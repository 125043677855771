<script setup lang="ts">
import type { VoixLinkField } from '@logicbomb-media/voix/dist/runtime/components/fields/link/types'

defineProps<{
  fields: {
    title: {
      value: string
    }
    subtitle: {
      value: string
    }
    copy: {
      value: string
    }
    link: {
      value: VoixLinkField
      enabled: boolean
    }
    cardAppearsOn: {
      value: string
    }
    media: {
      value: string
    }
  }
}>()

const target = ref(null)

defineSlice({
  name: { label: 'Paranza: Card Flanking Image', group: 'paranza' },
  fields: {
    title: { type: 'text', label: 'Title', default: 'Lorem Ipsum', name: 'title' },
    subtitle: { type: 'text', label: 'Subtitle', default: 'Neque Porro', name: 'subtitle' },
    copy: { type: 'wysiwyg', label: 'Copy', name: 'copy' },
    link: { type: 'link', label: 'Link', enabled: false, name: 'link' },
    cardAppearsOn: { type: 'select', label: 'Card appears on', default: 'left', options: { left: 'Left', right: 'Right' }, name: 'cardAppearsOn' },
    media: {
      type: 'media',
      label: 'Media',
      default: '\/imgs\/fpo.jpg',
      breakpoints: {
        default: { width: 683, height: 547 },
      },
      name: 'media',
    },
  },
  slots: [],
  preview: 'CardFlankingImage.jpg',
})
</script>

<template>
  <div v-if="fields" class=" py-12 bg-zinc-300">
    <div class="grid md:grid-cols-12 container max-w-5xl">
      <div
        class="md:col-span-4 flex py-8" :class="{
          'col-start-9': fields.cardAppearsOn.value === 'right',
        }"
      >
        <div
          class="flex bg-white py-6 w-full" :class="{
            ' pl-4': fields.cardAppearsOn.value === 'left',
            ' pr-4': fields.cardAppearsOn.value === 'right',
          }"
        >
          <div
            class="border border-zinc-300 py-12 px-8 w-full" :class="{
              'border-r-0': fields.cardAppearsOn.value === 'left',
              'border-l-0': fields.cardAppearsOn.value === 'right',
            }"
          >
            <RestaurantMicroChromeTitle :title="fields.title.value" :subtitle="fields.subtitle.value" />
            <RestaurantMicroChromeVerticalRule />
            <VoixWysiwyg
              v-if="fields.copy.value"
              :field="fields.copy"
              class="mt-3 prose prose-sm prose-zinc leading-snug font-light"
            />

            <div v-if="fields.link.enabled">
              <VoixLink ref="target" :field="fields.link.value" class="duration-500 ease-out inline-flex flex-col md:flex-row md:items-center md:space-x-4">
                <div class="text-xl uppercase font-light">
                  {{ fields.link.value?.text }}
                </div>
                <div class="flex justify-end">
                  <RestaurantMicroChromeParanzaArrow class="text-paranza-400 h-6 w-16" />
                </div>
              </VoixLink>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative md:col-span-8 overflow-hidden" :class="{
          'row-start-1 col-start-1': fields.cardAppearsOn.value === 'right',
        }"
      >
        <VoixMedia
          v-if="fields.media"
          :field="fields.media"
          class="absolute object-cover h-full w-full"
          width="683" height="547"
        />
      </div>
    </div>
  </div>
</template>
